import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.includes.js";
import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  props: {
    dataObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    lineitem: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    formatclass: {
      type: String,
      "default": "black--text" // this isn't right, dependent upon darkmode
    },
    decimals: {
      type: Number,
      "default": 2
    },
    currencytoggle: {
      type: Number,
      "default": 0
    },
    celltype: {
      type: String,
      "default": ""
    },
    showturns: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup(props) {
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode;
    var createNumberFormatter = function createNumberFormatter(decimals) {
      var lang = navigator.language || "default";
      return new Intl.NumberFormat(lang, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    };
    var formatNumber = computed(function () {
      return createNumberFormatter(props.decimals);
    });
    var createPercentFormatter = function createPercentFormatter(pctDecimals) {
      var lang = navigator.language || "default";
      return new Intl.NumberFormat(lang, {
        style: "percent",
        minimumFractionDigits: pctDecimals,
        maximumFractionDigits: pctDecimals
      });
    };
    var formatPercent = createPercentFormatter(1);
    return {
      formatPercent: formatPercent,
      refCode: refCode,
      formatNumber: formatNumber
    };
  },
  computed: {
    cellValue: function cellValue() {
      var data = this.dataObj;
      var type = this.celltype;
      var lineitem = this.lineitem;
      var showTurns = this.showturns; // data is an object where v is a number because of the calculation performed on the number
      // (data.v straight from the database is a string)
      // check if its Net Debt/EBITDA and needs to be a turns
      if (lineitem.name.includes("Net Debt/EBITDA") && data && !isNaN(data.dataitemvalue)) {
        var result = data.dataitemvalue;
        return "".concat(result > 0 ? "".concat(this.formatNumber.format(result)).concat(showTurns ? "x" : "") : "(".concat(this.formatNumber.format(Math.abs(result))).concat(showTurns ? "x" : "", ")"));
      } // ... why was I worried about NaN dataitemvalues? Becaues of percentages?
      if (data && !isNaN(data.dataitemvalue)) {
        var _result = data.dataitemvalue;
        if (type === "dxdt" && this.currencyToggle === 1) {
          if (data.fxdataitemvalue) {
            _result = data.fxdataitemvalue;
          } else {
            return;
          }
        }
        return _result > 0 ? "".concat(this.formatPercent.format(_result)) : "(".concat(this.formatPercent.format(Math.abs(_result)), ")");
      }
      return "";
    }
  }
});